<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    label?: string
    title?: string
    subtitle?: string
  }>(),
  {
    label: '',
    title: '',
    subtitle: '',
  },
)
defineSlots<{ header(): any; content(): any }>()

const aosAnimation = computed(() =>
  !!props.title && !!props.subtitle ? 'fade-up' : null,
)
</script>

<template>
  <section class="section-content">
    <base-container>
      <base-row class="section-content__header lg:justify-center">
        <base-col cols="24" lg="16">
          <div v-if="!!label" class="section-content__label">
            {{ label }}
          </div>
          <div
            :data-aos="aosAnimation"
            :data-aos-once="true"
            data-aos-offset="60"
          >
            <h2 v-if="!!title" class="section-content__title text-pre-line">
              {{ title }}
            </h2>
            <p v-if="!!subtitle" class="section-content__subtitle">
              {{ subtitle }}
            </p>
          </div>

          <slot name="header" />
        </base-col>
      </base-row>
    </base-container>

    <slot name="content" />
  </section>
</template>

<style>
.section-content__header {
  @apply flex items-center text-center;
}

.section-content__label {
  margin-bottom: 1rem;
  letter-spacing: 5px;
  @apply uppercase text-center;
}

.section-content__title {
  margin: 0 0 1.5rem 0;
}

@screen md {
  .section-content__title {
    @apply text-8xl;
  }
}

.section-content__subtitle {
  margin: 0 0 2rem 0;
  @apply text-lg;
}
</style>
